<template lang="">
<div class="settingContent">
    <div class="handleBox flex flex-jc-sb flex-ai-c">
        <h3 style="">{{$route.name}}</h3>
		<el-button type="primary" size="small" @click="handleEdit()">新建</el-button>
    </div>

    <div class="contentBox" style="width:100%">
		<el-tabs style="height:99%" v-model="tabName" @tab-click="handleClick">
            <el-tab-pane :label="i" :name="i" v-for="i in tabs">
				<el-table :data="list" height="calc( 100% - 40px )">
					<el-table-column label="日期">
						<template slot-scope="scope">
							{{scope.row.ext_from}} - {{scope.row.ext_to}}
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
							<el-button @click="handleDel(scope.row)" type="text">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
            </el-tab-pane>
        </el-tabs>
    </div>

	<meetAdd ref="meetAdd" :type="tabName" @success="getList(tabName)" :title="title"></meetAdd>
</div>
</template>
<script>
import meetAdd from "../../components/meetAdd.vue"; //relationship组件
export default {
	components:{
		meetAdd
	},
    data() {
        return {
			title:'新建',
			list:[],
			tabs: ['周', '月'],
			tabName:'周',
        };
    },
	mounted(){
		this.getList('周')
	},
    methods: {
		handleClick(tab) {
            this.getList(tab.label)
        },
        handleEdit(row){
			this.title= row?'编辑':'新建';
			this.$refs.meetAdd.open();
			if(row){
				this.$refs.meetAdd.formData={
					// id:row.id,
					to:String(row.to),
					from:String(row.from),
				};
			}else{
				this.$refs.meetAdd.formData={};
			}
		},
		getList(i){
			let url = i == '周' ? '/admin.sysMeet/indexWeek' : '/admin.sysMeet/indexMonth'
			this.http.post(url).then(re=>{
				this.list=re
			})
		},
		handleDel(row){
			this.$confirm('确定删除选中行吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
				let url ='/admin.sysMeet/delete'
                this.http.post(url, {
                    id: row.id
                }).then(re => {
                    this.getList(this.tabName);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            })
		}
    },
};
</script>
<style media="screen">
.el-tabs__content {
	height: calc(100% - 70px);
	> div {
		height: 100%;
	}
}
.el-tab-pane{
	height: 100%
}
</style>
