<template lang="">
<!-- 账号 新建 弹出 -->
<el-dialog :visible="personnelVisible" :title="title" :modal-append-to-body="true" :append-to-body="true" :close-on-click-modal="false" width="500px" @close="close()">
    <el-form ref="formAuth" :model="formData" label-position="top" :label-width="formLabelWidth" inline size="small" :show-message="false" :status-icon="true" class="alertForm">
		<el-row :gutter="24" type="flex" align="middle" v-if="type=='周'">
			<el-col :span="11">
				<el-form-item label="星期" prop="from" :required="type=='周'?true:false" style="width:100%">
					<el-select v-model="formData.from" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,index) in departmentList" :label="item.name" :value="item.id" :key="index">
						</el-option>
					</el-select>
		        </el-form-item>
			</el-col>
			<el-col :span="2">
				-
			</el-col>
			<el-col :span="11">
				<el-form-item label="星期" prop="to" :required="type=='周'?true:false" style="width:100%">
					<el-select v-model="formData.to" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,index) in departmentList" :label="item.name" :value="item.id" :key="index">
						</el-option>
					</el-select>
		        </el-form-item>
			</el-col>
		</el-row>

		<el-row :gutter="24" type="flex" align="middle" v-else>
			<el-col :span="11">
				<el-form-item label="日期（几号）" prop="from" :required="type=='月'?true:false" style="width:100%">
					<el-input v-model.number="formData.from" oninput="value=value.replace(/[^\.\d]/g,'')"></el-input>
		        </el-form-item>
			</el-col>
			<el-col :span="2">
				-
			</el-col>
			<el-col :span="11">
				<el-form-item label="日期" prop="to" :required="type=='月'?true:false" style="width:100%">
					<el-input v-model.number="formData.to" oninput="value=value.replace(/[^\.\d]/g,'')"></el-input>
		        </el-form-item>
			</el-col>
		</el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close()">取 消</el-button>
        <el-button size="small" type="primary" @click="submit()">确 定</el-button>
    </div>
</el-dialog>
</template>

<script>
export default {
    data() {
        return {
            formData: {}, //提交数据
            formLabelWidth: '80px', //弹出form label 宽
            personnelVisible: false, //显隐
            departmentList: [{
                name: '星期一',
                id: '1'
            }, {
                name: '星期二',
                id: '2'
            }, {
                name: '星期三',
                id: '3'
            }, {
                name: '星期四',
                id: '4'
            }, {
                name: '星期五',
                id: '5'
            }, {
                name: '星期六',
                id: '6'
            }, {
                name: '星期日',
                id: '7'
            }]
        }
    },
    props: [
        'title',
        'type'
    ],
    methods: {
        close: function() {
            this.personnelVisible = false;
            this.$refs.formAuth.resetFields();
            this.$emit('close');
        },
        open: function() {
            this.personnelVisible = true;
            // this.$refs.formAuth.resetFields();
        },
        success: function() {
            this.$emit('success');
        },

        submit: function() {
            // 验证
            this.$refs['formAuth'].validate((valid) => {
                if (valid) {
                    let url = '/admin.sysMeet/save';
					this.formData.type= this.type=='周'?1:2;
                    this.http.post(url, this.formData).then(() => {
                        this.close();
                        this.success(); //父页面刷子你列表
                        this.formData = {}; //清空
                        this.$message({
                            message: '提交成功!',
                            type: 'success'
                        });
                    });
                } else {
                    this.$message.error('必填选项不得为空');
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>
